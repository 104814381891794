
/**
 * Had issues with typescript recognising the Vuetify calendar methods and properties, see this gist for how this is fixed.
 * https://gist.github.com/Nicklas2751/da1fbe81878df7bb531fa4978c0ce7a8
 */

import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Route } from 'vue-router';
import { CalendarModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import UpcomingPostsSidebar from '@/components/sidebars/upcomingPostsSidebar.vue';
import PostDetailSidebar from '@/components/sidebars/postDetailSidebar.vue';
import { format } from 'date-fns';
import { PostListItem } from '@/models/posts/postListItem';
import { CalendarEvent } from '@/models/calendar/calendarEvent';
import { EventTypes, EventTypesLabels } from '@/constants';
import { CalendarTimestamp } from 'vuetify';
import { SidebarMixin } from '@/mixins/sidebarMixin';
import { getPostChannels } from '@/services/eventFactory';

const calendarModule = getModule(CalendarModule);

@Component({
  components: { UpcomingPostsSidebar, PostDetailSidebar },
  mixins: [SidebarMixin],
  methods: { getPostChannels },
})
export default class CalendarViewIndex extends LoggedInComponentBase {
  public excludedPosts: string[] = [];
  public start: CalendarTimestamp | null = null;
  public end: CalendarTimestamp | null = null;
  public type: string = 'month';
  public types: Array<string> = ['month', 'day'];
  public value: string = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public displayedEvents: Array<any> = [];
  public originalEvents: Array<CalendarEvent> = [];
  public showPostDetailSidebar: boolean = false;
  public selectedPost: PostListItem | null = null;
  public checkedChannels: Array<string> = [];
  public storeSocialChannels: Array<string> = [];
  public socialChannelFilters: Array<string> = ['Facebook', 'Instagram']; // TODO - Get this array's value dynamically

  private get isPostUpdated(): boolean {
    return this.postModalModule.isPostUpdated;
  }

  private get calendarInstance(): VCalendar {
    return this.$refs.calendar as VCalendar;
  }

  public get currentStore() {
    return this.userModule.currentStore;
  }

  public get moduleUpcomingPosts() {
    return calendarModule.upcomingPosts;
  }

  public get filteredDisplayedEvents(): CalendarEvent[] {
    return this.displayedEvents.filter((event: CalendarEvent) => {
      let hasChannels;
      if (event.eventType !== EventTypesLabels.Campaigns) {
        hasChannels = getPostChannels(event).some(
          (channel) => this.checkedChannels.indexOf(channel) >= 0
        );
      }

      return (
        (hasChannels || event.eventType === EventTypesLabels.Campaigns) &&
        // exclude filters
        !this.excludedPosts.includes(event.eventType)
      );
    });
  }

  public get filters() {
    return EventTypes;
  }

  public get title() {
    const { start, end } = this;
    if (!start || !end) {
      return '';
    }

    const startMonth = this.monthFormatter(start);
    const endMonth = this.monthFormatter(end);
    const suffixMonth = startMonth === endMonth ? '' : endMonth;
    const startYear = start.year;
    const endYear = end.year;
    const suffixYear = startYear === endYear ? '' : endYear;
    const startDay = start.day + this.nth(start.day);
    const endDay = end.day + this.nth(end.day);

    switch (this.type) {
      case 'month':
        return `${startMonth} ${startYear}`;
      case 'week':
      case '4day':
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
      case 'day':
        return `${startMonth} ${startDay} ${startYear}`;
    }
    return '';
  }

  public get monthFormatter() {
    return this.calendarInstance.getFormatter({
      timeZone: 'UTC',
      month: 'long',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getEventColour(event: any) {
    // @ts-ignore
    return this.filters?.find((x) => x.name === event?.eventType).colour;
  }

  private async created() {
    await this.checkSocialChannelsAuthorised();
    this.checkedChannels = [...this.socialChannelFilters];
  }

  private async refreshCalendar() {
    await calendarModule.getUpcomingPosts();
    await calendarModule.getPostsAndCampaigns({ fromDate: this.start?.date });
    this.displayedEvents = calendarModule.calendarEvents;
    this.originalEvents = [...this.displayedEvents];

    if (this.selectedPost) {
      this.selectedPost = this.displayedEvents.find(
        (post) => post.id === this.selectedPost?.id
      );
    }
  }

  public handlePostUpdated(): void {
    this.refreshCalendar();
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: CalendarViewIndex) => void)) => void
  ): Promise<void> {
    try {
      await calendarModule.getUpcomingPosts();
      await calendarModule.getPostsAndCampaigns();
      next();
    } catch (err) {
      next(false);
    }
  }

  prev() {
    this.calendarInstance.prev();
  }

  next() {
    this.calendarInstance.next();
  }

  nth(d: number) {
    return d > 3 && d < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
  }

  setToday() {
    this.value = this.getToday();
  }

  getToday() {
    return format(new Date(), 'yyyy-MM-dd');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showEvent({ nativeEvent, event }: { nativeEvent: Event; event: any }) {
    switch (event.eventType) {
      case EventTypesLabels.Campaigns: {
        this.$router.push({
          name: 'creativecentre.campaignDetail',
          params: { category: event.categories[0].id, id: event.id },
        });
        break;
      }

      default: {
        this.selectedPost = event;
        this.showPostDetailSidebar = true;
        break;
      }
    }

    nativeEvent?.stopPropagation();
  }

  async updateRange({
    start,
    end,
  }: {
    start: CalendarTimestamp;
    end: CalendarTimestamp;
  }) {
    this.start = start;
    this.end = end;
    await calendarModule.getPostsAndCampaigns({
      fromDate: start.date,
      toDate: end.date,
    });

    this.displayedEvents = calendarModule.calendarEvents;
    this.originalEvents = [...this.displayedEvents];
  }

  showMore({ date }: { date: string }) {
    this.value = date;
    this.type = 'day';
  }

  handleDayClick({ date }: { date: string }) {
    this.setToday();
    this.showPostModal(date);
  }

  public showPostModal(dateClicked: string) {
    const today = this.getToday();
    if (Date.parse(dateClicked) < Date.parse(today)) {
      // past date
      return;
    } else {
      this.postModalModule.setImmediate(false);
      this.postModalModule.setDate(dateClicked);
    }
    this.postModalModule.setVisible(true);
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    await calendarModule.getUpcomingPosts();
    await calendarModule.getPostsAndCampaigns();

    this.displayedEvents = calendarModule.calendarEvents;
    this.originalEvents = [...this.displayedEvents];

    this.showPostDetailSidebar = false;
    this.selectedPost = null;
  }

  @Watch('isPostUpdated')
  private async onPostUpdated(): Promise<void> {
    if (this.isPostUpdated) {
      await this.refreshCalendar();
    }
  }
}
