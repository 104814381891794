
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '../base/loggedInComponentBase';
import { format, isToday } from 'date-fns';
import { CalendarEvent } from '@/models/calendar/calendarEvent';
import { AltDrawerType } from '@/constants';
import { DailyPostsGroup } from '@/models/posts/dailyPostsGroup';
import PostPreview from '@/components/cards/postPreview.vue';
import PostPreviewEmpty from '@/components/cards/postPreviewEmpty.vue';

@Component({
  components: {
    PostPreview,
    PostPreviewEmpty,
  },
})
export default class UpcomingPostsSidebar extends LoggedInComponentBase {
  @Prop() upcomingPosts?: DailyPostsGroup[];
  @Prop({ default: false }) showCalendarLink?: boolean;

  public get displayCalendarLink(): boolean {
    return this.showCalendarLink || false;
  }

  public get upcomingPostsSidebarOpen(): boolean {
    return this.uiModule.upcomingPostsSidebarOpen;
  }

  public set upcomingPostsSidebarOpen(value: boolean) {
    this.uiModule.toggleAltDrawer({
      drawerType: AltDrawerType.UpcomingPostsSidebar,
      open: value,
    });
  }

  public closeButtonHandler() {
    this.uiModule.toggleAltDrawer({
      drawerType: AltDrawerType.UpcomingPostsSidebar,
      open: false,
    });
  }

  public createNewPost(group?: DailyPostsGroup) {
    if (group) {
      const dateClicked = group.date;

      this.createPostModule.setScheduledDate(format(dateClicked, 'yyyy-MM-dd'));
      this.createPostModule.setScheduledTime(format(dateClicked, 'K:mm'));

      if (!isToday(dateClicked)) {
        this.createPostModule.setIsScheduledImmediately(false);
      }
    }

    this.$router.push({ path: '/create-post' });
  }

  public showPost(post: CalendarEvent) {
    /**
     * We need to pass an object that mimics the object passed when an event on the actual calendar
     * is clicked, so we can call the same method whether we click on one of those events or on an 'event'
     * in this component.
     */
    this.$emit('show-post-detail', { nativeEvent: null, event: post });
  }
}
